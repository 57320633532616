import axios from "axios"
import { DateTime } from "luxon"
import { Employee } from "./employee"
import { TimeClock } from "./time-clock"
import { UserMe } from "./user-me"
import { WorkRecord } from "./work-record"
import { WorkRecordSummaries } from "./work-record-summaries"

const API_ENDPOINT =
  process.env.REACT_APP_FREEE_API_ENDPOINT || "https://api.freee.co.jp"
const AUTH_ENDPOINT =
  process.env.REACT_APP_FREEE_AUTHORIZATION_ENDPOINT ||
  "https://accounts.secure.freee.co.jp/public_api/authorize"
const TOKEN_ENDPOINT =
  process.env.REACT_APP_FREEE_TOKEN_ENDPOINT ||
  "https://accounts.secure.freee.co.jp/public_api/token"
const responseType = process.env.REACT_APP_FREEE_RESPONSE_TYPE || "code"

export const redirectUri = process.env.REACT_APP_FREEE_REDIRECT_URI!
export const clientId = process.env.REACT_APP_FREEE_CLIENT_ID!
export const authUri = `${AUTH_ENDPOINT}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}`

interface PostResponse {
  access_token: string
  token_type: string
  expires_in: number
  refresh_token: string
  scope: string
  created_at: number
}

export const getToken = async (code: string) => {
  const params = new URLSearchParams()
  params.append("code", code)
  params.append("grant_type", "authorization_code")
  params.append("client_id", process.env.REACT_APP_FREEE_CLIENT_ID!)
  params.append("client_secret", process.env.REACT_APP_FREEE_CLIENT_SECRET!)
  params.append("redirect_uri", process.env.REACT_APP_FREEE_REDIRECT_URI!)
  const { data } = await axios.post<PostResponse>(TOKEN_ENDPOINT, params)
  return data
}

function createAxios(token: string) {
  return axios.create({
    baseURL: API_ENDPOINT,
    timeout: 10000,
    headers: {
      "Content-Type": "application/json",
      "FREEE-VERSION": "2022-02-01",
      Authorization: `Bearer ${token}`,
    },
  })
}

export async function getMe(token: string): Promise<UserMe> {
  const { data } = await createAxios(token).get<UserMe>("/hr/api/v1/users/me")
  return data
}

export async function getWorkRecordSummaries(
  token: string,
  companyId: number,
  employeeId: number,
  year: number,
  month: number,
  detailed: boolean,
): Promise<WorkRecordSummaries> {
  const { data } = await createAxios(token).get<WorkRecordSummaries>(
    `/hr/api/v1/employees/${employeeId}/work_record_summaries/${year}/${month}`,
    { params: { company_id: companyId, work_records: detailed } },
  )
  return data
}

export async function getEmployees(
  token: string,
  companyId: number,
): Promise<Employee[]> {
  const { data } = await createAxios(token).get<Employee[]>(
    `/hr/api/v1/companies/${companyId}/employees`,
  )
  return data
}

export async function getWorkRecord(
  token: string,
  companyId: number,
  employeeId: number,
  year: number,
  month: number,
  day: number,
): Promise<WorkRecord> {
  const { data } = await createAxios(token).get<WorkRecord>(
    `/hr/api/v1/employees/${employeeId}/work_records/${`${year}`.padStart(
      2,
      "0",
    )}-${`${month}`.padStart(2, "0")}-${`${day}`.padStart(2, "0")}`,
    { params: { company_id: companyId } },
  )
  return data
}

export async function getTimeClocks(
  token: string,
  companyId: number,
  employeeId: number,
  year: number,
  month: number,
): Promise<TimeClock[]> {
  const firstDateOfMonth = DateTime.fromObject({ year, month, day: 1 })
  const { data } = await createAxios(token).get<TimeClock[]>(
    `/hr/api/v1/employees/${employeeId}/time_clocks`,
    {
      params: {
        company_id: companyId,
        from_date: firstDateOfMonth.toISODate(),
        to_date: firstDateOfMonth.endOf("month").toISODate(),
        limit: 100,
      },
    },
  )
  return data
}
