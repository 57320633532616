import { LocalizationProvider } from "@mui/lab"
import DateAdapter from "@mui/lab/AdapterDateFns"
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Stack,
} from "@mui/material"
import { Box } from "@mui/system"
import jaLocale from "date-fns/locale/ja"
import MainLayout from "layouts/MainLayout"
import WorkRecordDetailPage from "pages/work-record-detail-page"
import { authUri } from "./api/freee"
import { useAuth } from "./hooks/useAuth"

function App() {
  const { isAuthenticated, isAuthenticating, hasError } = useAuth()

  if (isAuthenticating) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <Stack alignItems="center">
          <CircularProgress color="inherit" />
        </Stack>
      </Backdrop>
    )
  }

  if (!isAuthenticated || hasError) {
    return (
      <Container>
        <Box
          p={4}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button variant="contained" size="large" component="a" href={authUri}>
            freee ログイン
          </Button>
        </Box>
      </Container>
    )
  }

  return (
    <LocalizationProvider dateAdapter={DateAdapter} locale={jaLocale}>
      <MainLayout>
        <WorkRecordDetailPage />
      </MainLayout>
    </LocalizationProvider>
  )
}

export default App
