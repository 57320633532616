import { FormControl, NativeSelect } from "@mui/material"
import { Employee } from "api/freee/employee"
import { useState } from "react"

type Props = {
  employees: Employee[]
  selectedEmployee?: Employee
  showDisabled?: boolean
  onChange: (value: Employee) => void
}

export default function EmployeePicker(props: Props) {
  const [employeeId, setEmployeeId] = useState<number>(
    props.selectedEmployee?.id ?? -1,
  )

  if (props.employees.length === 0 && employeeId < 0) {
    return null
  }

  const menuItems = props.employees
    .filter((x) => (props.showDisabled ? true : !x.retire_date))
    .map((employee) => (
      <option key={employee.id} value={employee.id}>
        {employee.display_name}
      </option>
    ))

  const handleChange = (event: any) => {
    const eid: number = parseInt(event.target.value)
    setEmployeeId(eid)
    const employee = props.employees.find((employee) => employee.id === eid)
    if (employee) {
      props.onChange(employee)
    }
  }

  return (
    <FormControl>
      <NativeSelect
        id="employee-select"
        value={employeeId}
        onChange={handleChange}
      >
        {menuItems}
      </NativeSelect>
    </FormControl>
  )
}
