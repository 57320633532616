export type DayPattern =
  | ""
  | "normal_day"
  | "prescribed_holiday"
  | "legal_holiday"

export const DayPatterns = {
  "": "",
  normal_day: "通常勤務",
  prescribed_holiday: "所定休日",
  legal_holiday: "法定休日",
}
