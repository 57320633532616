import { createContext, useContext, useEffect, useState } from "react"
import { getToken, redirectUri } from "../api/freee"
import {
  getAccessToken,
  removeSessionToken,
  setSessionToken,
} from "../api/session"

interface UseAuth {
  isAuthenticated: boolean
  isAuthenticating: boolean
  hasError: boolean
  accessToken: string
  logout: () => void
}

const authContext = createContext({} as UseAuth)

export const ProvideAuth: React.FC = ({ children }) => {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => {
  return useContext(authContext)
}

const useProvideAuth = (): UseAuth => {
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [accessToken, setAccessToken] = useState("")

  useEffect(() => {
    ;(async () => {
      // response_type = token の場合 (URL ハッシュに #access_token=<TOKEN>&token_type=bearer&expires_in=86400 の形式で access token が返る)
      const hashs = new URLSearchParams(window.location.hash.replace("#", ""))
      const accessToken = hashs.get("access_token")
      // response_type = code の場合 (※要バックエンド。 code が返るので token endpoint で access token を取得する)
      const params = new URLSearchParams(window.location.search)
      const code = params.get("code")
      if (accessToken) {
        // response_type = token の場合のみ
        setIsAuthenticating(false)
        setIsAuthenticated(true)
        setAccessToken(accessToken)
        setSessionToken(accessToken, "") // refresh_token はない
      } else if (code) {
        // response_type = code の場合
        try {
          const { access_token, refresh_token } = await getToken(code)
          setSessionToken(access_token, refresh_token)
          window.location.href = redirectUri
        } catch (err) {
          console.log(err)
          setHasError(true)
        }
      } else {
        setIsAuthenticating(false)
        const accessToken = getAccessToken()
        if (accessToken) {
          setIsAuthenticated(true)
          setAccessToken(accessToken)
        } else {
          setHasError(true)
        }
      }
    })()
  }, [])

  const logout = () => {
    setIsAuthenticated(false)
    setAccessToken("")
    removeSessionToken()
  }

  return { isAuthenticated, isAuthenticating, hasError, accessToken, logout }
}
