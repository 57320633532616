export type SchedulePattern =
  | ""
  | "substitute_holiday_work"
  | "substitute_holiday"
  | "compensatory_holiday_work"
  | "compensatory_holiday"
  | "special_holiday"

export const SchedulePatterns = {
  "": "",
  substitute_holiday_work: "振替出勤",
  substitute_holiday: "振替休日",
  compensatory_holiday_work: "代休出勤",
  compensatory_holiday: "代休",
  special_holiday: "特別休暇",
}
