import {
  Logout as LogoutIcon,
  Refresh as RefreshIcon,
} from "@mui/icons-material"
import {
  AppBar,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material"
import { getTimeClocks, getWorkRecordSummaries } from "api/freee"
import { DayPatterns } from "api/freee/day-pattern"
import { Employee } from "api/freee/employee"
import { SchedulePatterns } from "api/freee/schedule-pattern"
import { TimeClock } from "api/freee/time-clock"
import { WorkRecord } from "api/freee/work-record"
import { WorkRecordSummaries } from "api/freee/work-record-summaries"
import EmployeePicker from "components/employee-picker"
import YearMonthPicker from "components/year-month-picker"
import { useAuth } from "hooks/useAuth"
import { useFreeeHr } from "hooks/useFreeeHr"
import { DateTime } from "luxon"
import { ReactNode, useEffect, useState } from "react"

export default function WorkRecordDetailPage() {
  const { isAuthenticated, hasError, accessToken, logout } = useAuth()
  const { employees } = useFreeeHr()
  const [isLoading, setIsLoading] = useState(true)
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>()
  const [yearMonth, setYearMonth] = useState(DateTime.now().startOf("month"))
  const [workRecordSummaries, setWorkRecordSummaries] =
    useState<WorkRecordSummaries>()
  const [timeClocks, setTimeClocks] = useState<TimeClock[]>([])
  const [refreshKey, setRefreshKey] = useState(0)
  useEffect(() => {
    if (!isAuthenticated || hasError) {
      return
    }
    if (employees.length > 0 && !selectedEmployee) {
      setSelectedEmployee(employees[0])
      return
    }
    let unmounted = false
    const f = async () => {
      if (unmounted) {
        return
      }
      setIsLoading(true)
      if (selectedEmployee) {
        const workRecordSummaries = await getWorkRecordSummaries(
          accessToken,
          724104,
          selectedEmployee.id,
          yearMonth.year,
          yearMonth.month,
          true,
        )
        console.log(workRecordSummaries)
        const dates = workRecordSummaries.work_records
          ?.map((x) => x.date)
          .sort()
        if (dates) {
          const ym = DateTime.fromISO(dates[0])
          const timeClocks = await getTimeClocks(
            accessToken,
            724104,
            selectedEmployee.id,
            ym.year,
            ym.month,
          )
          console.log(timeClocks)
          setTimeClocks(timeClocks)
        }
        setWorkRecordSummaries(workRecordSummaries)
        setIsLoading(false)
      }
    }
    f()
    return () => {
      unmounted = true
    }
  }, [
    isAuthenticated,
    hasError,
    accessToken,
    employees,
    selectedEmployee,
    yearMonth,
    refreshKey,
  ])

  const workRecords = workRecordSummaries?.work_records

  const getTimeString = (time: string | null | undefined) => {
    return time ? DateTime.fromISO(time).toFormat("HH:mm") : ""
  }

  const getTimeClockIn = (date: string) => {
    const tcs = timeClocks.filter(
      (tc) => tc.type === "clock_in" && tc.date === date,
    )
    return tcs.map((x) => getTimeString(x?.datetime)).join("\n")
  }

  const getTimeClockOut = (date: string) => {
    const tcs = timeClocks.filter(
      (tc) => tc.type === "clock_out" && tc.date === date,
    )
    return tcs.map((x) => getTimeString(x?.datetime)).join("\n")
  }

  const formatToDayCount = (days: number): ReactNode => {
    return (
      <>
        <Typography variant="body1" component="span">
          {days}
        </Typography>
        <Typography variant="caption">日</Typography>
      </>
    )
  }

  const formatToHourMinutes = (minutes: number): ReactNode => {
    const h = Math.floor(minutes / 60)
    const m = minutes % 60
    return (
      <>
        {h > 0 ? (
          <>
            <Typography variant="body1" component="span">
              {h}
            </Typography>
            <Typography variant="caption">時間</Typography>
          </>
        ) : null}
        <Typography variant="body1" component="span">
          {m}
        </Typography>
        <Typography variant="caption">分</Typography>
      </>
    )
  }

  const summaryItems: {
    label: string
    format: (x: WorkRecordSummaries) => ReactNode | string | null | undefined
  }[] = [
    { label: "労働日数", format: (x) => formatToDayCount(x.work_days) },
    {
      label: "総労働",
      format: (x) => formatToHourMinutes(x.total_work_mins),
    },
    {
      label: "所定内労働",
      format: (x) => formatToHourMinutes(x.total_normal_work_mins),
    },
    {
      label: "法定内労働",
      format: (x) => formatToHourMinutes(x.total_excess_statutory_work_mins),
    },
    {
      label: "時間外労働",
      format: (x) =>
        formatToHourMinutes(x.total_overtime_except_normal_work_mins),
    },
    {
      label: "休日労働",
      format: (x) => formatToHourMinutes(x.total_holiday_work_mins),
    },
    {
      label: "深夜労働",
      format: (x) => formatToHourMinutes(x.total_latenight_work_mins),
    },
    { label: "欠勤日数", format: (x) => formatToDayCount(x.num_absences) },
    {
      label: "早退遅刻",
      format: (x) =>
        formatToHourMinutes(x.total_lateness_and_early_leaving_mins),
    },
    {
      label: "有給取得数",
      format: (x) => formatToDayCount(x.num_paid_holidays),
    },
  ]

  const formatToMinutes = (minutes: number): ReactNode => {
    return (
      <Typography
        sx={{
          color: (theme) =>
            minutes === 0
              ? theme.palette.text.disabled
              : theme.palette.text.primary,
        }}
      >
        {minutes}
      </Typography>
    )
  }

  const formatMemo = (row: WorkRecord): ReactNode => {
    const result = []
    if (row.note) {
      result.push(row.note)
    }
    const manuals = []
    if (row.total_excess_statutory_work_mins > 0) {
      const statutory = [
        `法定内残業: ${row.total_excess_statutory_work_mins}分`,
      ]
      if (row.total_latenight_excess_statutory_work_mins > 0) {
        statutory.push(
          `(うち深夜: ${row.total_latenight_excess_statutory_work_mins}分)`,
        )
      }
      manuals.push(statutory.join(" "))
    }
    if (manuals.length > 0) {
      result.push(manuals.join(", "))
    }
    return result.join("\n")
  }

  const columns: {
    id: string
    label: string | null
    minWidth?: number
    align?: "right" | "left" | "center"
    noHeader?: boolean
    sticky?: boolean
    colSpan?: number
    wrap?: boolean
    fontSize?: string
    format: (value: WorkRecord) => string | ReactNode | null
  }[] = [
    {
      id: "date",
      label: "日付",
      align: "center",
      minWidth: 80,
      sticky: true,
      format: (row) => (
        <Box
          sx={{
            color: (theme) =>
              row.day_pattern !== "normal_day"
                ? theme.palette.grey[500]
                : undefined,
          }}
        >
          {DateTime.fromISO(row.date).toFormat("MM/dd")}
          <Typography variant="caption" ml={1}>
            {DateTime.fromISO(row.date).weekdayShort}
          </Typography>
        </Box>
      ),
    },
    {
      id: "day_pattern",
      label: "パターン",
      align: "center",
      minWidth: 80,
      fontSize: "0.8em",
      format: (row) =>
        [
          row.day_pattern ? DayPatterns[row.day_pattern] : undefined,
          row.is_absence ? "欠勤" : undefined,
          row.schedule_pattern
            ? SchedulePatterns[row.schedule_pattern]
            : undefined,
        ].map((x, i) => <Box key={`${row.date}_${i}`}>{x}</Box>),
    },
    {
      id: "time_recorder_clock_in",
      label: "レコーダー",
      align: "center",
      minWidth: 50,
      colSpan: 2,
      format: (row) => getTimeClockIn(row.date),
    },
    {
      id: "time_recorder_clock_out",
      label: "",
      align: "center",
      minWidth: 50,
      noHeader: true,
      format: (row) => getTimeClockOut(row.date),
    },
    {
      id: "clock_in",
      label: "勤務時間",
      align: "center",
      minWidth: 50,
      colSpan: 2,
      format: (row) => getTimeString(row.clock_in_at),
    },
    {
      id: "clock_out",
      label: "",
      align: "center",
      minWidth: 50,
      noHeader: true,
      format: (row) => getTimeString(row.clock_out_at),
    },
    {
      id: "break_in",
      label: "休憩時間",
      align: "center",
      minWidth: 50,
      colSpan: 2,
      format: (row) =>
        row.break_records
          ?.map((b) => getTimeString(b.clock_in_at))
          .sort()
          .join("\n") ?? "",
    },
    {
      id: "break_out",
      label: "",
      align: "center",
      minWidth: 50,
      noHeader: true,
      format: (row) =>
        row.break_records
          ?.map((b) => getTimeString(b.clock_out_at))
          .sort()
          .join("\n") ?? "",
    },
    {
      id: "total_overtime_work_mins",
      label: "時間外",
      align: "right",
      minWidth: 50,
      format: (row) =>
        formatToMinutes(row.total_overtime_except_normal_work_mins),
    },
    {
      id: "total_holiday_work_mins",
      label: "休日",
      align: "right",
      minWidth: 50,
      format: (row) => formatToMinutes(row.total_holiday_work_mins),
    },
    {
      id: "total_latenight_work_mins",
      label: "深夜",
      align: "right",
      minWidth: 50,
      format: (row) => formatToMinutes(row.total_latenight_work_mins),
    },
    {
      id: "early_leaving_lateness_mins",
      label: "早遅",
      align: "right",
      minWidth: 50,
      format: (row) =>
        formatToMinutes(row.early_leaving_mins + row.lateness_mins),
    },
    {
      id: "paid_holiday",
      label: "有給",
      align: "right",
      minWidth: 50,
      format: (row) => (
        <>
          <Box
            component="span"
            sx={{
              color: (theme) =>
                row.paid_holiday > 0
                  ? theme.palette.text.primary
                  : theme.palette.text.disabled,
            }}
          >
            {formatToDayCount(row.paid_holiday)}
          </Box>
          {row.hourly_paid_holiday_mins
            ? formatToMinutes(row.hourly_paid_holiday_mins)
            : undefined}
        </>
      ),
    },
    {
      id: "note",
      label: "メモ",
      align: "left",
      minWidth: 200,
      fontSize: "0.8em",
      wrap: true,
      format: formatMemo,
    },
  ]
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateRows: "auto 1fr",
        height: "100%",
      }}
    >
      {/* ローディング レイヤー */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* ヘッダー */}
      <AppBar position="relative" sx={{ zIndex: 10 }}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Box
              display="inline-block"
              p={1}
              m={1}
              sx={{
                backgroundColor: "white",
                borderRadius: "4px",
              }}
            >
              <YearMonthPicker value={yearMonth} onChange={setYearMonth} />
            </Box>
            {employees.length > 0 && (
              <Box
                display="inline-block"
                p={1}
                m={1}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "4px",
                }}
              >
                <EmployeePicker
                  employees={employees}
                  selectedEmployee={selectedEmployee}
                  onChange={setSelectedEmployee}
                />
              </Box>
            )}
          </Box>
          <IconButton
            color="inherit"
            onClick={() => setRefreshKey((old) => old + 1)}
          >
            <RefreshIcon />
          </IconButton>
          <IconButton color="inherit" onClick={() => logout()}>
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {/* サイドバー|テーブル */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          height: "100%",
          width: "100%",
          overflow: "hidden",
        }}
      >
        {/* サイドバー */}
        <Paper
          elevation={3}
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
            overflow: "auto",
            zIndex: 5,
            height: "100%",
          }}
        >
          <Box p={2}>
            <Stack spacing={2}>
              {workRecordSummaries &&
                summaryItems.map((item) => (
                  <Box key={item.label}>
                    <Typography variant="caption">{item.label}</Typography>
                    <Typography align="right">
                      {item.format(workRecordSummaries)}
                    </Typography>
                  </Box>
                ))}
            </Stack>
          </Box>
        </Paper>
        {/* テーブル */}
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: "100%" }}>
            <Table stickyHeader size="small" aria-label="sticky table">
              {/* ヘッダー */}
              <TableHead>
                <TableRow>
                  {columns.map(
                    (column) =>
                      column.noHeader !== true && (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          colSpan={column.colSpan || 1}
                          sx={{
                            position: column.sticky ? "sticky" : undefined,
                            left: column.sticky ? 0 : undefined,
                            zIndex: column.sticky ? 3 : undefined,
                            minWidth: column.minWidth,
                            whiteSpace: "pre",
                            fontSize: "0.8em",
                            fontWeight: "bold",
                            px: "4px",
                            borderRightWidth: "1px",
                            borderRightStyle: "solid",
                            borderRightColor: (theme) => theme.palette.divider,
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ),
                  )}
                </TableRow>
              </TableHead>
              {/* ボディー */}
              <TableBody>
                {workRecords?.map((row) => {
                  return (
                    <TableRow tabIndex={-1} key={row.date}>
                      {columns.map((column) => {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{
                              position: column.sticky ? "sticky" : undefined,
                              left: column.sticky ? 0 : undefined,
                              zIndex: column.sticky ? 2 : undefined,
                              whiteSpace: column.wrap ? "pre" : undefined,
                              verticalAlign: "top",
                              fontSize: column.fontSize || "1rem",
                              backgroundColor: (theme) =>
                                column.sticky
                                  ? theme.palette.background.paper
                                  : undefined,
                              minWidth: column.minWidth,
                              px: "4px",
                              borderRightWidth: "1px",
                              borderRightStyle: "solid",
                              borderRightColor: (theme) =>
                                theme.palette.divider,
                            }}
                          >
                            {column.format(row)}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  )
}
