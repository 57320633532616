import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { ReactNode } from "react"

const mdTheme = createTheme()

export default function MainLayout({ children }: { children: ReactNode }) {
  return (
    <ThemeProvider theme={mdTheme}>
      <Box
        sx={{
          height: "100%",
        }}
      >
        <CssBaseline />
        <Box
          component="main"
          sx={{
            height: "100%",
            overflow: "hidden",
          }}
        >
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  )
}
