import { FormControl, NativeSelect } from "@mui/material"
import { DateTime, Interval } from "luxon"

type Props = {
  value: DateTime
  onChange: (value: DateTime) => void
}

export default function YearMonthPicker(props: Props) {
  const start = DateTime.fromISO("2016-01-01").setZone("Asia/Tokyo")
  const now = DateTime.now().setZone("Asia/Tokyo")
  const nextMonth = now.plus({ month: 1 }).startOf("month")
  const endOfNextMonth = nextMonth
  const intervalMonths = Math.floor(
    Interval.fromDateTimes(start, endOfNextMonth).length("months"),
  )
  const months = [...Array(intervalMonths + 1)].map((_, i) =>
    start.plus({ months: i }),
  )

  const menuItems = months.map((month, i) => (
    <option key={i} value={month.toFormat("yyyy-MM")}>
      {month.toFormat("yyyy年MM月")}
    </option>
  ))

  const handleChange = (event: any) => {
    const firstDate = DateTime.fromISO(`${event.target.value}-01`)
    props.onChange(firstDate)
  }

  return (
    <FormControl>
      <NativeSelect
        id="year-month-select"
        value={props.value.toFormat("yyyy-MM")}
        onChange={handleChange}
      >
        {menuItems}
      </NativeSelect>
    </FormControl>
  )
}
